





























import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/shared/resources/components/Icon.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import { redirect } from '@/shared/lib/support/router/RouterUtils';

@Component({
  components: {
    Button,
    Divider,
    Icon,
  },
})
export default class IconMessage extends Vue {
  /**
   * Props
   */
  @Prop() private title!: string;
  @Prop() private icon!: string;
  @Prop({ default: 'accent--text text--lighten-2' }) private iconClass!: string;
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: false, type: Boolean }) private success!: boolean;
  @Prop({ default: false, type: Boolean }) private error!: boolean;
  @Prop({ default: false, type: Boolean }) private showDashboardButton!: boolean;

  /**
   * Display getters
   */
  private get displayDashboardButton() {
    return this.showDashboardButton;
  }

  /**
   * Class names
   */
  private get classNames(): object {
    return {
      'icon-message': true,
      'small': this.small,
    };
  }

  private get iconClassNames(): object {
    return {
      'success--text': this.success,
      'error--text': this.error,
      [this.iconClass]: !this.success && !this.error,
    };
  }

  /**
   * Methods
   */
  private goToDashboard() {
    redirect({ name: 'dashboard' });
  }

  /**
   * Handlers
   */
  private onGoToDashboardButtonClick() {
    this.goToDashboard();
  }
}
